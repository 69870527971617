<template>
  <div class="movimentazioni">
    <h1>Movimentazioni</h1>
    <p>Ciao</p>
  </div>
</template>

<script>
export default {
  name: "app",
  created:function() {
    localStorage.idmodule = '1'
  }
}
</script>